import "../App.css";
import { createRef, useEffect, useState } from "react";
import {
  Forward10,
  Pause,
  PlayArrow,
  Replay,
  Replay10,
  VolumeOff,
  VolumeUp,
} from "@material-ui/icons";
import english from '../assets/english.mp4'
import data from "../data.json";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  CssBaseline,
  Grid,
} from "@material-ui/core";
import SideMenu from "../SideMenu/SideMenu";
import TopBar from "../TopBar/TopBar";
import background from "../assets/background.jpg";
import logo from "../assets/logo.png";
import arIcon from "../assets/ar.png";
import enIcon from "../assets/en.png";

function EnglishPage() {
  const videoRef = createRef();
  const [previousSection, setPreviousSection] = useState();
  const [playing, setPlaying] = useState(false);
  const [videoData, setVideoData] = useState(data);
  const [popup, setPopup] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    if (popup) return;
    setDrawerOpen(!drawerOpen);
  };
  const drawerOnClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    // setVideoData(data);
  }, []);

  const init = () => {
    let duration = 0;
    let lastInteractionTime = 0;
    if (videoRef && videoRef.current) {
      duration = videoRef.current.duration;
    }
    let sections = data.interactions.map((interaction) => {
      let time = interaction.time || duration;
      interaction.duration = time - lastInteractionTime;
      lastInteractionTime = interaction.time;
      interaction.disabled = true;
      return interaction;
    });
    setVideoData({ ...videoData, duration, sections });
  };

  const toggleSound = (muted = false) => {
    console.log("toggleSound");
    const x = videoRef.current;
    x.muted = !x.muted;
    setVideoData({ ...videoData, muted: x.muted });
  };

  const toggleVideo = () => {
    if (popup) return;
    const x = videoRef.current;
    if (x.paused) {
      x.play();
    } else {
      x.pause();
    }
  };

  const seekVideo = (forward = true) => {
    if (videoRef && videoRef.current) {
      let time = videoRef.current.currentTime;
      let currentSection = videoData.interactions.find(
        (interaction) => interaction.active === true
      );
      if (forward) {
        time += 10;
      } else {
        time -= 10;
      }
      if (time < currentSection.start) {
        time = currentSection.start;
      }
      if (currentSection.time && time >= currentSection.time) {
        time = currentSection.time - 1;
      }
      videoRef.current.currentTime = time;
    }
  };

  const goToPreviousOptions = () => {
    if (videoRef && videoRef.current && previousSection) {
      const videoElm = videoRef.current;
      videoElm.currentTime = previousSection.time;
    }
  };

  const replaySection = (section) => {
    setPreviousSection(null);
    if (videoRef && videoRef.current) {
      const videoElm = videoRef.current;
      // if (!section.active && !section.disabled) {
      videoElm.currentTime = section.start;
      videoElm.play();
      // }
    }
  };

  const timeChange = () => {
    const x = videoRef.current;
    if (videoData) {
      // setProgress((x.currentTime / x.duration) * 100);
      if (videoData.interactions) {
        const interactions = videoData.interactions.map((interaction) => {
          interaction.active =
            x.currentTime >= interaction.start &&
            (x.currentTime < interaction.time || !interaction.time);
          if (interaction.active) {
            interaction.disabled = false;
          }
          if (Math.round(x.currentTime) === Math.round(interaction.time)) {
            if (interaction.actions.length > 0) {
              setPopup(interaction);
              x.pause();
            }
          }
          return interaction;
        });
        setVideoData({ ...videoData, interactions });
      }
    }
  };

  const continueVideo = (t) => {
    let currentSection = videoData.interactions.find(
      (interaction) => interaction.active === true
    );
    if (currentSection) setPreviousSection(currentSection);
    const x = videoRef.current;
    x.currentTime = t.time;
    if (Math.round(x.currentTime) === Math.round(t.time)) {
      x.currentTime += 1;
    }
    x.play();
    setPopup();
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${background})` }}>
      <div className="language-selector">
        <p>Choose Language</p>
        <Button href="/arabic">
          <img src={arIcon} alt="Arabic" />
        </Button>
        <Button href="/english">
          <img src={enIcon} alt="English" />
        </Button>
      </div>
      <img className="logo" src={logo} alt="logo" />
      {videoData && (
        <div className="backgroundVideo">
          <CssBaseline />
          <ClickAwayListener onClickAway={drawerOnClose}>
            <div>
              <TopBar open={drawerOpen} handleDrawerOpen={handleDrawerOpen} />
              <SideMenu
                sections={videoData.sections}
                goToSection={replaySection}
                open={drawerOpen}
                onClose={drawerOnClose}
              />
            </div>
          </ClickAwayListener>
          <h1>{videoData.title}</h1>
          <video
            ref={videoRef}
            id="video1"
            onLoadedData={init}
            poster={videoData.poster}
            onTimeUpdate={timeChange}
            onPlaying={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onVolumeChange={() =>
              setVideoData({ ...videoData, muted: videoRef.current.muted })
            }
            playsInline
          >
            <source id="mp4" src={english} type="video/mp4" />
            <p>Your user agent does not support the HTML5 Video element.</p>
          </video>
          <div className="content toggleOnHover">
            <Box p={2}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => seekVideo(false)}
                    >
                      <Replay10 />
                    </Button>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={() => seekVideo(true)}
                    >
                      <Forward10 />
                    </Button> */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toggleVideo}
                    >
                      {(playing && <Pause />) || <PlayArrow />}
                    </Button>
                    {previousSection && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={goToPreviousOptions}
                      >
                        Back to options
                      </Button>
                    )}
                  </ButtonGroup>
                </Grid>
                <Grid item>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toggleSound}
                    >
                      {(videoData.muted && <VolumeOff />) || <VolumeUp />}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      )}

      {popup && (
        <div className="lightbox">
          {popup.actions.map((t, i) => (
            <div
              className="button longbtns box1"
              onClick={() => continueVideo(t)}
              key={i}
              dangerouslySetInnerHTML={{ __html: t.html }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EnglishPage;
