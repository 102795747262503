import "../App.css";
import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import background from "../assets/select-lang-bg.png";
import logo from "../assets/logo.png";
import arIcon from "../assets/ar.png";
import enIcon from "../assets/en.png";

function HomePage() {
  const [loaded, setLoaded] = useState(false);
  const audio = new Audio("https://creativesad.com/osteoporosis/sound.mp3");

  useEffect(() => {
    audio.addEventListener("loadeddata", () => {
      setLoaded(true);
    });
  }, []);

  return (
    <div
      className="App"
      style={{ backgroundImage: `url(${background})` }}
      onMouseDown={() => {
        audio.loop = true;
        if (loaded) audio.play();
      }}
    >
      <img className="logo" src={logo} alt="logo" />
      <div className="select-lang-page">
        <h1 className="big-title">Bones Matter</h1>
        <div className="language-selector">
          <p>Choose Language</p>
          <Button href="/arabic">
            <img src={arIcon} alt="Arabic" />
          </Button>
          <Button href="/english">
            <img src={enIcon} alt="English" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
