import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import { ChevronRight, Close } from "@material-ui/icons";
import sideMenuStyles from "./SideMenuStyle";
import clsx from "clsx";

function SideMenu(props) {
  const classes = sideMenuStyles();

  const sectionOnClick = (section) => {
    props.onClose();
    props.goToSection(section);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
      open={props.open}
      onClose={props.onClose}
    >
      <div className={classes.drawerHeader}>
        {/* <IconButton onClick={props.onClose} color="inherit">
          <Close />
        </IconButton> */}
        Video Library
      </div>
      <Divider />
      <List>
        {props.sections &&
          props.sections.map((section, index) => (
            <ListItem
              button
              divider
              key={index}
              onClick={() => sectionOnClick(section)}
              className={classes.listItem}
            >
              <span> - </span>
              <div
                className={clsx([
                  classes.listItemText,
                  section.active && classes.listItemTextActive,
                ])}
                dangerouslySetInnerHTML={{ __html: section.title }}
              />
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
}

export default SideMenu;
